<template>
  <div>
    <ed-esocial-configuracao
      :key="$root.$session.versao"
    />
  </div>
</template>

<script>
import EdEsocialConfiguracao from "@/components/notaFiscal/configuracao/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {
    EdEsocialConfiguracao
  },
  mounted() {
  },
  beforeDestroy() {
  },
  provide() {
    return {
    };
  },
  created() {},
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
