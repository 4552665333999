<template>
  <div class="row mb-3">
    <ed-input-text
    class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
      label="Login / CMC"
      name="strLoginCmc"
      v-model="formData.configuracaoNotaFiscal.strValor.strLoginCmc"
      :items="bind.arrayTipoAmbiente"
      rules="required"
    />

    <ed-input-text
      class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
      label="Senha"
      name="strSenhaCmc"
      v-model="formData.configuracaoNotaFiscal.strValor.strSenhaCmc"
      rules="required"
      type="password"
    />

  </div>
</template>

<script>
import {
  EdInputText,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: null,
    },
  },
  components: {
    EdInputText
  },
  inject: ["bind"],
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>
