<template>
  <div v-if="formData && formData.configuracaoNotaFiscal.strValor">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Informações Gerais</span>

        <div class="row mb-3">
          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-0 pt-0"
            label="Ambiente"
            name="intTipoAmbiente"
            v-model="formData.configuracaoNotaFiscal.strValor.intTipoAmbiente"
            :items="bind.arrayTipoAmbiente"
            rules="required"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-0 pt-0"
            label="Número Série"
            name="strSerie"
            v-model="formData.configuracaoNotaFiscal.strValor.strSerie"
            rules="required"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-0 pt-0"
            label="Número RPS"
            name="intNumeroRps"
            v-model="formData.configuracaoNotaFiscal.strValor.intNumeroRps"
            rules="required"
            type="number"
          />
        </div>

        <araucaria 
          v-if="$root.$session.cliente.endereco && $root.$session.cliente.endereco.municipio && $root.$session.cliente.endereco.municipio.strCodigoIbge == '4101804'"
          :formData="formData" :key="$root.$session.versao"
        />

        <!-- <span class="form-title">Templates</span>

        <div class="row">
          <ed-input-editor
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
            label="Emissão de NFS-e"
            name="intTipoAmbiente"
            extraPlugins="edol"
            v-model="formData.configuracaoNotaFiscal.strValor.strMensagemEmissao"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { EdInputSelect, EdInputEditor,EdInputText } from "@/components/common/form";
import Araucaria from './araucaria.vue'

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: null,
    },
  },
  components: {
    Araucaria,
    EdInputSelect,
    EdInputEditor,
    EdInputText
  },
  inject: ["bind"],
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>
