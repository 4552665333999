<template>
  <div v-if="bind.boolCarregado">
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Configuração Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData.configuracaoNotaFiscal.strValor"
      :handlerSave="strTab != 'tab-status' ? salvar : null"
      v-if="!this.$root.$session.loadingContent"
    >
      <cadastro-geral
        v-show="strTab == 'tab-cadastro'"
        :formData="formData"
        :key="'cadastro-geral-' + $root.$session.versao + strKey"
      />
    </ed-form>
  </div>
</template>

<script>
import CadastroGeral from "./partials/geral.vue";

import { EdForm } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {},
  components: {
    CadastroGeral,
    EdForm,
  },
  provide() {
    return {
      bind: this.bind,
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        boolCarregado: false,
        arrayTipoAmbiente: [
          { intId: 1, strNome: "Produção" },
          { intId: 2, strNome: "Homologação" },
        ],
      },
      strTab: "tab-cadastro",
      strKey: this.$utilidade.randomString(),
      formData: {
        configuracaoNotaFiscal: {
          intId: 0,
          strChave:'configuracaoNotaFiscal',
          intParametroId:null,
          strNome: "Configuração Nota Fiscal",
          strValor: {
            intTipoAmbiente: 2,
            strSerie: null,
            intNumeroRps: null,
            strMensagemEmissao: null,
            strLoginCmc: null,
            strSenhaCmc: null,
          },
        },
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.$root.$request.get("Common/ParametroFilial", {
          strChave: "configuracaoNotaFiscal",
          boolBuscarSomenteAtivo: false,
        }),
      ]).then(([objConfiguracao]) => {
        this.$root.$dialog.loading(false);

        if (objConfiguracao.status == 200) {
          if (objConfiguracao.result && objConfiguracao.result.strValor) {
            this.formData = {
              configuracaoNotaFiscal: Object.assign(objConfiguracao.result, {}),
            };
          }
        }

        this.bind.boolCarregado = true;
      });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("Common/ParametroFilial", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            (this.strKey = this.$utilidade.randomString()), this.initialize();
          }
        });
    },
  },
};
</script>
